import React, { useEffect, useRef, useState } from "react";
import AboutPage from "../assets/960x0.webp";
import AboutImage from "../assets/about10.jpg";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import Tick from "../assets/Pro tip.png";
import Coal from "../assets/Occupational Health and Safety Management Systems Guidelines.jpg";
import Expert from "../assets/Alcazar-banner-07-removebg-preview.png";

import { FaGlobe, FaMobile, FaLaptop, FaBullhorn, FaPalette, FaShoppingCart, FaGraduationCap, FaPlane } from 'react-icons/fa';

import Blog1 from "../assets/blog1.jpg";

import Icons1 from "../assets/b1.png";
import Icons2 from "../assets/b2.png";
import Icons3 from "../assets/b3.png";
import Icons4 from "../assets/b4.png";
import Icons5 from "../assets/b5.png";

import Images1 from "../assets/vector.png";
import Images2 from "../assets/chip.png";
import Images3 from "../assets/android.png";
import Image1 from "../assets/fe3.jpg";
import Image2 from "../assets/fe4.jpg";
import Image3 from "../assets/fe1.jpg";


import 'swiper/css';
import 'swiper/css/autoplay';



import Right from "../assets/angle-double-small-right.png";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ImageGallery from "../components/ImageGallery";
import TeamSection from "../components/TeamSection";
import LogoSlider from "../components/LogoSlider";
import TestimonialSlider from "../components/TestimonialSlider";


const services = [
  { title: 'Web Development', description: 'Custom websites tailored to your industry, designed for performance, scalability, and a seamless user experience. From simple landing pages to complex web applications, we have you covered.', icon: <FaGlobe /> },
  { title: 'App Development', description: 'Innovative mobile applications promoting engagement and growth. Our apps are designed to be user-friendly, feature-rich, and compatible with both iOS and Android platforms.', icon: <FaMobile /> },
  { title: 'Software Development', description: 'Comprehensive software solutions for your business needs. We specialize in developing robust, scalable, and efficient software that drives business success and enhances operational efficiency.', icon: <FaLaptop /> },
  { title: 'Digital Marketing', description: 'Data-driven strategies to enhance your online presence. We use SEO, PPC, social media, and content marketing to reach your target audience and achieve your marketing goals.', icon: <FaBullhorn /> },
  { title: 'UI/UX Designing', description: 'Stunning, intuitive interfaces for seamless user experiences. Our design process focuses on creating visually appealing and user-centric designs that improve user satisfaction and engagement.', icon: <FaPalette /> },
  { title: 'E-Commerce Development', description: 'Robust online stores to boost your digital sales. We develop secure, scalable, and user-friendly e-commerce platforms that offer a smooth shopping experience and drive conversions.', icon: <FaShoppingCart /> },
  { title: 'Study Abroad', description: 'Comprehensive guidance for international education experiences. From choosing the right institution to visa applications, we provide end-to-end support to help you achieve your academic goals abroad.', icon: <FaGraduationCap /> },
  { title: 'Abroad Recruitment', description: 'Connecting global talent with international opportunities. We assist candidates in finding the right job overseas and help companies in recruiting the best talent from around the world.', icon: <FaPlane /> },
];

const companies = [
  { logo: Icons1 }, { logo: Icons2 }, { logo: Icons3 }, { logo: Icons4 }, { logo: Icons5 },
  { logo: Icons1 }, { logo: Icons2 }, { logo: Icons3 }, { logo: Icons4 }, { logo: Icons5 }
];

const card = [
  { icon: Images1, image1: Images1, image2: Image1, title: "Maintenance Service", description: "Orci varius natoque penatibus etagnis dis parturient montes, nascetur iculus tellus mi placerat sapien." },
  { icon: Images2, image1: Images2, image2: Image2, title: "Best IT Security for All", description: "Orci varius natoque penatibus etagnis dis parturient montes, nascetur iculus tellus mi placerat sapien." },
  { icon: Images3, image1: Images3, image2: Image3, title: "All Kind Of IT Services", description: "Orci varius natoque penatibus etagnis dis parturient montes, nascetur iculus tellus mi placerat sapien." },
];

const plans = [
  {
    title: 'Basic Package',
    price: '₹10,999',
    description: 'Essential Web Solutions',
    features: [
      'Responsive Website (5 Pages)',
      'Basic SEO Optimization',
      'Mobile-Friendly Design',
      'Contact Form Integration',
      'Social Media Integration',
      '2 Months Free Maintenance',
      'SSL Certificate',
      '24/7 Email Support'
    ]
  },
  {
    title: 'Professional Package',
    price: '₹25,999',
    description: 'Advanced E-commerce Solution',
    features: [
      'Full E-commerce Website',
      'Payment Gateway Integration',
      'Custom Admin Dashboard',
      'Inventory Management System',
      'Advanced SEO Package',
      'Order Management System',
      'Premium Support 24/7',
      'Performance Optimization'
    ]
  },
  {
    title: 'Enterprise Package',
    price: '₹45,999',
    description: 'Complete Digital Transformation',
    features: [
      'Custom ERP Integration',
      'Advanced Analytics Dashboard',
      'AI-Powered Chatbot',
      'Mobile App Development',
      'Cloud Infrastructure Setup',
      'Dedicated Project Manager',
      'SLA Guaranteed Uptime',
      'Priority Technical Support'
    ]
  }
];

const About = () => {
  const countUpRef = useRef(null);
  const [startCountUp, setStartCountUp] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 2000 });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartCountUp(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (countUpRef.current) {
      observer.observe(countUpRef.current);
    }

    return () => {
      if (countUpRef.current) {
        observer.unobserve(countUpRef.current);
      }
    };
  }, []);



  const [activeTab, setActiveTab] = useState('Web development');
  const [images ] = useState([
    { id: 1, src: Blog1, alt: 'Image in Web development', category: 'Web development', heading: 'Project 1', description: 'This is a project from Web development.' },
    { id: 2, src: Blog1, alt: 'Image in Web development', category: 'Web development', heading: 'Project 2', description: 'This is another project from Web development.' },
    { id: 3, src: Blog1, alt: 'Image in Web development', category: 'Web development', heading: 'Project 3', description: 'This is another project from Web development.' },
    { id: 4, src: Blog1, alt: 'Image in Web development', category: 'Web development', heading: 'Project 4', description: 'This is another project from Web development.' },
    { id: 5, src: Blog1, alt: 'Image in Web development', category: 'Web development', heading: 'Project 5', description: 'This is another project from Web development.' },
    { id: 6, src: Blog1, alt: 'Image in Web development', category: 'Web development', heading: 'Project 6', description: 'This is another project from Web development.' },
    { id: 7, src: Blog1, alt: 'Image in App development', category: 'App development', heading: 'Project 7', description: 'This is a project from App development.' },
    { id: 8, src: Blog1, alt: 'Image in Software development', category: 'Software development', heading: 'Project 8', description: 'This is a project from Software development.' },
    { id: 9, src: Blog1, alt: 'Image in Digital Marketing', category: 'Digital Marketing', heading: 'Project 9', description: 'This is a project from Digital Marketing.' },
    { id: 10, src: Blog1, alt: 'Image in UI/UX Designing', category: 'UI/UX Designing', heading: 'Project 10', description: 'This is a project from UI/UX Designing.' },
    { id: 11, src: Blog1, alt: 'Image in E-Commerce Development', category: 'E-Commerce Development', heading: 'Project 11', description: 'This is a project from E-Commerce Development.' },
    { id: 12, src: Blog1, alt: 'Image in Study Abroad', category: 'Study Abroad', heading: 'Project 12', description: 'This is a project from Study Abroad.' },
    { id: 13, src: Blog1, alt: 'Image in Abroad Recruitment', category: 'Abroad Recruitment', heading: 'Project 13', description: 'This is a project from Abroad Recruitment.' }
  ]);

  const [transitioning, setTransitioning] = useState(false);

  const handleTabClick = (category) => {
    setTransitioning(true);
    setTimeout(() => {
      setActiveTab(category);
    }, 500);
  };

  

  useEffect(() => {
    if (transitioning) {
      const timeoutId = setTimeout(() => {
        setTransitioning(false);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [transitioning]);


  

  



  return (
    <div className="relative h-[300px] md:h-[400px] lg:h-[500px]">
      <Navbar/>
      <div>

        <div className="relative h-[300px] md:h-[400px] lg:h-[500px]">
          <img src={AboutPage} alt="About background" className="absolute inset-0 w-full h-full object-cover opacity-70" />
          <div className="relative flex items-center justify-center h-full bg-black bg-opacity-50 text-white">
            <div className="text-center">
              
              <p className="mt-2 text-sm md:text-base">
                
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content-home-page">
        <section className="w-full max-w-7xl mx-auto py-10">
          <div className="grid md:grid-cols-2 gap-6 md:gap-4">
            <div className="flex justify-center items-center">
              <img fetchpriority="high" decoding="async" width="558" height="564" src={AboutImage} alt="Feature" className="object-cover w-full h-auto max-w-md" />
            </div>
            <div>
              <div className="text-left">
              <h1 className="text-xl md:text-2xl font-bold text-[#00294B] tracking-wide uppercase">
  EXPLORE FEATURES
</h1>
<h2 className="text-2xl md:text-4xl font-bold text-black">
  Join The New Innovation With Technical Workforce
</h2>
                <p className="text-gray-600 mt-4 text-base md:text-xl font-poppins text-justify px-4 max-w-6xl mx-auto">
  Sekenz Group is a leading web and app development company specializing in custom solutions for businesses worldwide. We deliver innovative, user-friendly, and scalable digital products, ensuring exceptional quality and client satisfaction.
</p>
              </div>
              <section className="flex flex-col md:flex-row container mx-auto py-6 gap-4">
                <div className="w-full md:w-1/2 p-2">
                  <div className="text-left">
                    <h2 className="text-2xl font-bold mb-4">Our Facility</h2>
                    <p className="mb-4">Fugit nemo quia consequuntur aspe magni dolores</p>
                    <ul className="list-disc list-inside space-y-2">
                      <li className="flex items-center">
                        <img src={Tick} alt="Tick Icon" className="w-6 h-6 mr-2" />
                        Testing for Traces Impurities.
                      </li>
                      <li className="flex items-center">
                        <img src={Tick} alt="Tick Icon" className="w-6 h-6 mr-2" />
                        In Vitro Testing for Cosmetics.
                      </li>
                      <li className="flex items-center">
                        <img src={Tick} alt="Tick Icon" className="w-6 h-6 mr-2" />
                        The serves a seamless exten.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col space-y-6">
                  <div className="flex items-center justify-start space-x-4">
                    <div className="text-4xl text-[#1B1F2E]">
                      <img src={Coal} alt="Coal Production" className="w-12 h-12" />
                    </div>
                    <div className="text-start">
  <div className="text-2xl md:text-4xl font-bold text-[#1B1F2E]">
    {startCountUp ? <CountUp start={0} end={86} duration={3} suffix="%" /> : "0%"}
  </div>
  <p className="mt-2 text-base md:text-xl font-bold text-gray-600">Coal Production</p>
</div>

<div className="flex items-center space-x-4">
  <div className="text-2xl md:text-4xl text-[#1B1F2E]">
    <img src={Expert} alt="Expert Developers" className="w-8 h-8 md:w-12 md:h-12" />
  </div>
  <div className="text-start">
    <div className="text-2xl md:text-4xl font-bold text-[#1B1F2E]">
      {startCountUp ? <CountUp start={0} end={866} duration={3} suffix="+" /> : "0+"}
    </div>
    <p className="mt-2 text-base md:text-xl font-bold text-gray-600">Expert Developers</p>
  </div>
</div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h1 className="text-2xl md:text-lg font-bold tracking-wide uppercase">Explore Services</h1>
            <h3 className="text-3xl leading-9 font-extrabold text-gray-800 sm:text-4xl sm:leading-10">See what we can do for your business</h3>
          </div>
          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <div key={index} className="relative overflow-hidden bg-white p-6 shadow-lg border-l-2 border-[#00294B] transition-all duration-500 group">
                <div className="absolute inset-0 left-0 h-full w-0 bg-[#00294B] transition-all duration-300 group-hover:w-full"></div>
                <div className="relative z-10 flex items-center justify-center h-20 w-20 rounded-full bg-[#00294B] text-white mb-4 text-2xl transition-all duration-300 group-hover:bg-white">
                  <span className="text-white transition-all duration-300 group-hover:text-[#00294B]">{service.icon}</span>
                </div>
                <div className="relative z-10 text-left">
                  <h4 className="text-lg font-semibold text-gray-900 transition-all duration-300 group-hover:text-white hover:text-blue-900">{service.title}</h4>
                  <p className="mt-2 text-base text-gray-700 transition-all duration-300 group-hover:text-white">{service.description}</p>
                  <button className="mt-4 text-[#00294B] rounded-md border-2 border-gray-200 font-medium z-10 h-12 w-36 transition-all duration-300 group-hover:bg-white">Read More</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
<ImageGallery/>
     <LogoSlider/>
      <div className="py-12 bg-white">
        <div className="max-w-6xl mx-auto grid md:grid-cols-3 gap-8">
          {card.map((item, index) => (
            <div key={index} className="relative group overflow-hidden bg-white shadow-lg transition-transform duration-500 ease-in-out">
              <div className="card-bg absolute inset-0" style={{ backgroundImage: `url(${item.image2})` }}></div>
              <div className="relative z-10 p-11 flex flex-col bg-white justify-end h-full transition-transform duration-500 ease-in-out group-hover:translate-y-[-26%]">
                <div className="group-hover:-translate-y-[150%] group-hover:scale-0 group-hover:opacity-0 transform transition duration-500">
                  <div className="flex justify-center items-center mb-4 text-blue-500 text-4xl">
                    <img src={item.icon} alt={`${item.title} icon`} className="w-16 h-16 transition-transform duration-500 ease-in-out group-hover:scale-125" />
                  </div>
                </div>
                <div className="absolute inset-0 flex justify-center items-center transition-all duration-500 ease-in-out opacity-0 group-hover:opacity-10">
                  <img src={item.icon} alt={`${item.title} icon`} className="w-32 h-32 mt-20" />
                </div>
                <h3 className="text-xl font-bold mb-4 text-center">{item.title}</h3>
                <p className="text-gray-600 text-center">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-100 py-16">
        <div className="container mx-auto text-center mb-8 px-4 md:px-8">
          <h2 className="text-4xl font-extrabold mb-6 text-center">Best Facility</h2>
        </div>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 justify-center px-4 md:px-44">
          {plans.map((plan, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-8 relative overflow-hidden">
              {plan.popular && (
                <div className="absolute top-3 -right-10 bg-[#00294B] text-white text-lg px-10 py-2 rotate-45">Popular</div>
              )}
              <div className="text-center mb-4">
                {/* <img src={plan.icon} alt={plan.title} className="h-12 mx-auto mb-8" /> */}
                <p className="text-black text-xl truncate mb-8">{plan.description}</p>
                <h3 className="text-4xl font-bold mb-14">{plan.price}</h3>
                <p className="text-lg font-medium bg-[#00294B] text-white px-8 py-2 rounded-full inline-block mb-8 relative before:content-[''] before:absolute before:w-[150px] before:h-[1px] before:bg-blue-900 before:left-[-120px] before:top-1/2 before:-translate-y-1/2 after:content-[''] after:absolute after:w-[150px] after:h-[1px] after:bg-blue-900 after:right-[-120px] after:top-1/2 after:-translate-y-1/2">
                  {plan.title}
                </p>
              </div>
              <ul className="text-gray-400 mb-6">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center justify-center mb-3">
                    <span className="mr-2">
                      <img src={Right} alt="Arrow" className="inline-block h-8 w-8" />
                    </span>
                    {feature}
                  </li>
                ))}
              </ul>
              <button className="mt-4 bg-[#00294B] text-lg text-white px-6 py-2 rounded-md font-medium z-10 h-12 w-36 border border-blue-900 hover:bg-white hover:text-blue-900 hover:border-current">Buy now</button>
            </div>
          ))}
        </div>
      </div>

     <TeamSection/>
   <TestimonialSlider/>

<Footer/>
    </div>
  );
}

export default About;
