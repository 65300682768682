import React, { useState, useEffect } from 'react';
import Img1 from "../assets/images/fordesign.jpg";
import hash3609 from '../assets/hash360.png'
import upgrad from '../assets/upgrad.png'
import talksglobal from '../assets/talksglobal.png'
import sunfocus from '../assets/sunfocus.png'
import safefocuz from '../assets/safe.png'
import kuppaya from '../assets/kuppaaya.png'

const ImageGallery = () => {
  const [activeTab, setActiveTab] = useState('Web development');
  const [images, setImages] = useState([
    { id: 1, src: talksglobal, alt: 'Image in Web development', category: 'Web development', heading: 'Study Abroad', description: 'This is a project from Web development.' },
    { id: 2, src: hash3609, alt: 'Image in Web development', category: 'Web development', heading: 'House Automation', description: 'This is another project from Web development.' },
    { id: 3, src: upgrad, alt: 'Image in Web development', category: 'Web development', heading: 'Study Abroad', description: 'This is another project from Web development.' },
    { id: 4, src: sunfocus, alt: 'Image in Web development', category: 'Web development', heading: 'Solar Solutions', description: 'This is another project from Web development.' },
    { id: 5, src: safefocuz, alt: 'Image in Web development', category: 'Web development', heading: 'Security solutions', description: 'This is another project from Web development.' },
    { id: 6, src: kuppaya, alt: 'Image in Web development', category: 'Web development', heading: 'Ecommerce store', description: 'This is another project from Web development.' },
    { id: 7, src: Img1, alt: 'Image in App development', category: 'App development', heading: 'Project 7', description: 'This is a project from App development.' },
    { id: 8, src: Img1, alt: 'Image in Software development', category: 'Software development', heading: 'Project 8', description: 'This is a project from Software development.' },
    { id: 9, src: Img1, alt: 'Image in Digital Marketing', category: 'Digital Marketing', heading: 'Project 9', description: 'This is a project from Digital Marketing.' },
    { id: 10, src: Img1, alt: 'Image in UI/UX Designing', category: 'UI/UX Designing', heading: 'Project 10', description: 'This is a project from UI/UX Designing.' },
    { id: 11, src: Img1, alt: 'Image in E-Commerce Development', category: 'E-Commerce Development', heading: 'Project 11', description: 'This is a project from E-Commerce Development.' },
    { id: 12, src: Img1, alt: 'Image in Study Abroad', category: 'Study Abroad', heading: 'Project 12', description: 'This is a project from Study Abroad.' },
    { id: 13, src: Img1, alt: 'Image in Abroad Recruitment', category: 'Abroad Recruitment', heading: 'Project 13', description: 'This is a project from Abroad Recruitment.' }
  ]);

  const [transitioning, setTransitioning] = useState(false);

  const handleTabClick = (category) => {
    setTransitioning(true); 
    setTimeout(() => {
      setActiveTab(category); 
    }, 500); 
  };

  const filteredImages = images.filter(image => image.category === activeTab);

  useEffect(() => {
    if (transitioning) {
      const timeoutId = setTimeout(() => {
        setTransitioning(false); 
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [transitioning]);

  return (
    <div className="flex flex-col items-center p-4 md:p-8">
      <h3 className="text-base md:text-lg font-bold text-center">Explore Project</h3>
      <h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-6 text-center">Our Best Project</h1>

      <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4 mb-6 md:mb-8 w-full">
        {['Web development', 'App development', 'Software development', 'Digital Marketing', 'UI/UX Designing', 'E-Commerce Development', 'Study Abroad', 'Abroad Recruitment'].map((tab) => (
          <button
            key={tab}
            className={`p-2 md:p-4 rounded-md text-center w-full text-sm md:text-base ${
              activeTab === tab
                ? 'bg-[#00294B] text-white'
                : 'bg-white border text-gray-700 hover:bg-[#00294B] hover:text-white'
            } transition duration-300 ease-in-out`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {filteredImages.length > 0 ? (
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 transition-transform duration-500 ${transitioning ? 'transform translate-x-full opacity-0' : 'transform translate-x-0 opacity-100'}`}>
          {filteredImages.map((image) => (
            <div key={image.id} className="relative overflow-hidden group w-full h-48 md:h-64 transition-opacity duration-300">
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-black text-center bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-center items-center text-white">
                <div className="transition-transform delay-3000 duration-3000 transform translate-y-full group-hover:translate-y-0">
                  <h3 className="text-lg md:text-xl font-bold">{image.heading}</h3>
                  <p className="text-xs md:text-sm mt-2">{image.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-500">
          <p>No images available</p>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;