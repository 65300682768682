import React from 'react';

import BlogImg1 from '../assets/images/blog1.jpg';
import BlogImg2 from '../assets/images/blog2.jpg';
import BlogImg3 from '../assets/images/blog3.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock } from '@fortawesome/free-solid-svg-icons';

const NewsArticles = () => {
  const articles = [
    {
      id: 1,
      title: 'Short loin shankle meat on ball tongue spare ribs rump',
      author: 'Consalt',
      role: 'Automotive Engineer',
      date: 'October 1, 2024',
      description: 'At Sekenz Group, we leverage innovative software solutions to enhance e-commerce experiences. Our tools help businesses streamline operations and engage customers effectively.',
      image: BlogImg1,
    },
    {
      id: 2,
      title: 'Default Interest Rate in The Small Loans Now is Lowest',
      author: 'Consalt',
      role: 'Auto Journalist',
      date: 'September 25, 2024',
      description: 'With Sekenz Group’s analytics, businesses can navigate financial decisions in e-commerce more efficiently, optimizing lending processes and improving user satisfaction.',
      image: BlogImg2,
    },
    {
      id: 3,
      title: 'U.S. fund managers trim the bank stocks on profit worries',
      author: 'Consalt',
      role: 'Tech Analyst',
      date: 'September 20, 2024',
      description: 'As market dynamics shift, Sekenz Group’s e-commerce solutions empower businesses to adapt and thrive, ensuring they remain competitive in a rapidly changing landscape.',
      image: BlogImg3,
    },
  ];
  

  return (
    <div className="flex flex-col items-center my-10 p-6">
      <h2 className="text-sm text-gray-500 mb-1">Our Blog & Event</h2>
      <h1 className="text-3xl font-bold mb-6">News & Articles</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
        {articles.map((article) => (
          <div key={article.id} className="bg-white shadow-md flex flex-col overflow-hidden">
            <div className="relative group">
              <div className="w-full h-0 pb-[100%] overflow-hidden relative">
                <img
                  src={article.image}
                  alt={article.title}
                  className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-500 transform group-hover:scale-125"
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-200 delay-200"></div>
                <div className="absolute top-5 left-7 rounded-lg bg-blue-950 bg-opacity-50 text-white text-sm p-2 scale-0 group-hover:scale-100 transition-transform duration-000 ease-in-out hidden group-hover:block">
                  {article.date}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between p-4 h-full">
              <div>
                <h3 className="text-lg font-semibold">{article.title}</h3>
                <div className="flex items-center space-x-6 mt-2">
                  <div className="flex items-center">
                    <FontAwesomeIcon className='text-blue-950' icon={faUser} />
                    <span className="text-sm ml-3">{article.author}</span>
                  </div>
                  <div className="flex items-center">
                    <FontAwesomeIcon className='text-blue-950' icon={faClock} />
                    <span className="text-sm ml-1">{article.date}</span>
                  </div>
                </div>
                <p className="text-gray-700 mt-2">{article.description}</p>
              </div>
              <div className="flex justify-start mt-4">
                <button className="text-black mt-6 py-2 px-4 rounded-lg border hover:text-white hover:bg-blue-950">Read More</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsArticles;
