import React from 'react';
import { Search, Diamond } from 'lucide-react';
import Bt from '../assets/o.avif'
import Navbar from './Navbar';
import Footer from './Footer';

const CareersPage = () => {
  const jobs = [
    {
      id: 'GRIT-JR0000293',
      title: 'IT Sales Executive/Sr. IT Sales Executive',
      location: 'India,Kerala,Kochi',
      experience: '2+ Year',
      createdOn: 'October 29, 2024',
      openings: 4
    },
    {
      id: 'GRIT-JR0000292',
      title: 'Digital Marketing Manager',
      location: 'India,Kerala,Kozhikode',
      experience: '4+ Year',
      createdOn: 'October 29, 2024',
      openings: 1
    },
    {
      id: 'GRIT-JR0000291',
      title: 'IT Sales Director',
      location: 'India,Kerala,Kozhikode',
      experience: '8+ Year',
      createdOn: 'October 28, 2024',
      openings: 1
    },
    {
      id: 'GRIT-JR0000287',
      title: 'Product Analyst',
      location: 'India,Kerala,Kozhikode',
      experience: '1+ Year',
      createdOn: 'October 11, 2024',
      openings: 1
    },
    {
      id: 'GRIT-JR0000286',
      title: 'Product Engineer - Frontend React',
      location: 'India,Kerala,Kozhikode',
      experience: '2+ Year',
      createdOn: 'October 11, 2024',
      openings: 1
    },
    {
      id: 'GRIT-JR0000289',
      title: 'Video Editor',
      location: 'India,Kerala,Kozhikode',
      experience: '1+ Year',
      createdOn: 'October 17, 2024',
      openings: 1
    }
  ];

  return (
    <div className="bg-white">
        <Navbar/>
      {/* Career Section */}
      <div className="max-w-6xl mx-auto px-4 py-16">
        {/* Top Section with Core Values */}
        <div className="text-center mb-20">
          <h2 className="text-gray-800 uppercase tracking-wide text-lg mb-4">
            Work ethics, innovative mindset, common sense and
            trustworthiness are the prime qualities we expect.
          </h2>
          <p className="text-gray-800 uppercase tracking-wide text-lg">
            The rest is our job.
          </p>
        </div>

        {/* Dotted Pattern */}
        <div className="grid grid-cols-12 gap-2 mb-20">
          {[...Array(144)].map((_, i) => (
            <div key={i} className="w-1 h-1 rounded-full bg-gray-200" />
          ))}
        </div>

        {/* Bottom Section with Image and Text */}
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          {/* Left Text Content */}
          <div className="md:w-1/2">
            <h3 className="text-3xl font-semibold text-gray-800 mb-6">
              This opportunity is not for everyone, but for those who choose dedication and hard work over ease and comfort.
            </h3>
            <p className="text-gray-600 leading-relaxed">
            At Sekenz Group, we’re driven by the belief that choice defines our journey. We respect the power of individual decisions, recognizing that true freedom lies in the ability to choose one’s path. Valuing life means empowering each person to make choices that resonate with their vision and purpose.            </p>
          </div>

          {/* Right Image/Illustration */}
          <div className="md:w-1/2">
            <div className="relative">
              {/* Office Setting */}
              <div className="border-t-2 border-gray-200 w-full absolute bottom-0" />
              
              {/* Bookshelf */}
              <div className="absolute top-0 right-0 w-32 h-20 border-2 border-gray-200" />
              
              {/* People Working */}
              <div className="relative overflow-hidden">
                <img 
                    src={Bt}
                    alt="Office environment"
                    className="w-full h-[300px] md:h-[400px] lg:h-[500px] object-cover rounded-lg shadow-lg transition-transform hover:scale-105 duration-300"
                />
                </div>
            </div>
          </div>
        </div>
      </div>

      {/* Job Listings Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4">
          {/* Header Section */}
          <div className="text-center mb-12">
            <h2 className="text-gray-700 uppercase tracking-wide text-sm mb-4">
              Current Vacancies
            </h2>
            <p className="text-2xl text-gray-800 max-w-3xl mx-auto">
              Choose the right option that fits your skills and qualifications,
              and apply to experience the new world of opportunities.
            </p>
          </div>

          {/* Search Bar */}
          <div className="flex gap-4 mb-12">
            <div className="flex-1 relative">
              <input
                type="text"
                placeholder="Search Jobs at Gritstone"
                className="w-full px-4 py-3 pl-12 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00294B] focus:border-transparent"
              />
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            <button className="px-8 py-3 bg-[#00294B] text-white rounded-md hover:bg-[#003765] transition-colors">
              Search
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {jobs.map((job) => (
        <div key={job.id} className="bg-white rounded-lg border border-gray-200 p-6 flex flex-col h-full">
          {/* Job Header */}
          <div className="flex items-start gap-4 mb-6">
            <div className="p-2 bg-gray-200 rounded-lg">
              <Diamond className="h-6 w-6 text-[#00294B]" />
            </div>
            <div>
              <h3 className="font-medium text-lg text-gray-900 mb-1">
                {job.title}
              </h3>
              <p className="text-gray-600 text-sm">{job.location}</p>
            </div>
          </div>

          {/* Job Details */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <p className="text-sm text-gray-500 mb-1">Job ID</p>
              <p className="text-sm text-gray-900">{job.id}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-500 mb-1">Experience</p>
              <p className="text-sm text-gray-900">{job.experience}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500 mb-1">Job Created on</p>
              <p className="text-sm text-gray-900">{job.createdOn}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-500 mb-1">Openings</p>
              <p className="text-sm text-gray-900">{job.openings}</p>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="mt-auto flex justify-between items-center">
            <button className="px-6 py-2 bg-[#00294B] text-white rounded-md hover:bg-[#003765] transition-colors">
              Apply Now
            </button>
            <button className="text-cyan-500 hover:text-cyan-600 text-sm">
              View Details
            </button>
          </div>
        </div>
      ))}
    </div>



        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default CareersPage;