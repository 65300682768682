import React, { useState, useEffect } from "react";
import HeroBgImg from "../assets/images/Section.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import RotatingDots from "./RotatingDots";
import businees from '../assets/business.jpg'

const HeroSection = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState("");

  const content = [
    {
      heading: "Web & Mobile App Development Solutions",
      subheading:
        "We offer a wide range of software development services to meet your business needs.",
    },
    {
      heading: "Innovate and Inspire",
      subheading: "Join our journey to innovation",
    },
    {
      heading: "Achieve Greatness",
      subheading: "Let's build something awesome together",
    },
  ];

  const handlePrev = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setDirection("left");
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? content.length - 1 : prevIndex - 1
        );
        setIsAnimating(false);
      }, 500);
    }
  };

  const handleNext = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setDirection("right");
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === content.length - 1 ? 0 : prevIndex + 1
        );
        setIsAnimating(false);
      }, 500);
    }
  };

  const handleServiceClick = () => {
    navigate('/services');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handlePrev();
    }, 5000);

    return () => clearInterval(interval);
  }, [isAnimating]);

  return (
    <div className="relative bg-slate-500 z-10 h-screen overflow-hidden">
      {/* Background container with zoom animation */}
      <div 
        className="absolute inset-0 bg-cover bg-center animate-slow-zoom"
        style={{ 
          backgroundImage: `url(${HeroBgImg})`,
        }}
      />
      {/* Background container with zoom animation */}
      <div 
        className="absolute inset-0 bg-cover bg-center animate-slow-zoom"
        style={{ 
          backgroundImage: `url(${businees})`,
        }}
      />
      
      {/* RotatingDots positioned on the left */}
      <div className="absolute left-8 top-1/2 transform -translate-y-1/2 z-20">
        <RotatingDots />
      </div>

      <div className="absolute inset-0 bg-black opacity-50 z-0"></div>

      <div className="text-center text-white space-y-6 relative overflow-hidden w-full h-full">
        <div
          className={`absolute inset-0 transition-transform duration-500 ease-in-out flex flex-col items-center justify-center space-y-4 ${
            isAnimating
              ? direction === "right"
                ? "translate-x-full"
                : "-translate-x-full"
              : "translate-x-0"
          }`}
          key={currentIndex}
        >
          <div>
            <h1 className="text-3xl md:text-5xl lg:text-7xl font-bold font-[Poppins] px-4 md:px-10 lg:px-44">
              {content[currentIndex].heading}
            </h1>
            <p className="mt-4 mb-8 px-4 md:px-20 lg:px-[50vh] text-lg md:text-xl lg:text-2xl font-[Poppins]">
              {content[currentIndex].subheading}
            </p>

            <div className="space-x-4 mt-4">
              <button 
                onClick={handleServiceClick}
                className="bg-[#00294B] hover:bg-blue-500 z-10 text-white py-3 md:py-4 px-8 md:px-10 rounded-lg transition duration-300 font-[Poppins] text-sm md:text-base"
              >
                Quick Service
              </button>
              <button className="border hover:bg-[#00294B] z-50 text-white py-3 md:py-4 px-8 md:px-10 rounded-lg transition duration-300 font-[Poppins] text-sm md:text-base">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={handlePrev}
        className="absolute left-5 text-white top-1/2 transform -translate-y-1/2 p-4 rounded-full hidden sm:block"
      >
        <FontAwesomeIcon size="2x" icon={faChevronLeft} />
      </button>
      <button
        onClick={handleNext}
        className="absolute right-5 text-white top-1/2 transform -translate-y-1/2 p-4 rounded-full hidden sm:block"
      >
        <FontAwesomeIcon size="2x" icon={faChevronRight} />
      </button>

      <style jsx>{`
        @keyframes slow-zoom {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(1.1);
          }
        }
        
        .animate-slow-zoom {
          animation: slow-zoom 10s linear infinite alternate;
        }
      `}</style>
    </div>
  );
};

export default HeroSection;