import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import Testi1 from '../assets/images/testi1.png';
import Testi2 from '../assets/images/testi2.png';
import Testi3 from '../assets/images/testi3.png';

const TestimonialSlider = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const testimonials = [
    { img: Testi1, name: 'Poral Dawson', role: 'Manager', quote: 'Sekenz Group has transformed our e-commerce platform, enhancing user experience and driving sales effectively.' },
    { img: Testi2, name: 'Jane Smith', role: 'Data Scientist', quote: 'The testing and quality assurance from Sekenz Group have ensured that our products are reliable and user-friendly.' },
    { img: Testi3, name: 'Alice Johnson', role: 'Designer', quote: 'Working with Sekenz Group has elevated our design process, making it more user-centric and innovative.' },
    { img: Testi1, name: 'Michael Lee', role: 'Project Manager', quote: 'Sekenz Group’s solutions have streamlined our project management, leading to timely and successful deliveries.' },
    { img: Testi1, name: 'Sarah Brown', role: 'Product Owner', quote: 'The insights from Sekenz Group have been invaluable in aligning our products with user needs and market demands.' },
  ];
  

  const totalCards = testimonials.length;

  const getNumVisibleCards = () => {
    if (window.innerWidth >= 1024) {
      return 3; 
    } else if (window.innerWidth >= 640) {
      return 2; 
    } else {
      return 1;
    }
  };

  const [numVisibleCards, setNumVisibleCards] = useState(getNumVisibleCards());

  useEffect(() => {
    const handleResize = () => {
      setNumVisibleCards(getNumVisibleCards());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextCard();
    }, 3000);
    return () => clearInterval(interval);
  }, [currentCard]);

  const nextCard = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentCard((prevCard) => (prevCard + 1) % (totalCards + numVisibleCards));
    }
  };

  const prevCard = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentCard((prevCard) => (prevCard - 1 + totalCards + numVisibleCards) % (totalCards + numVisibleCards));
    }
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);

    if (currentCard >= totalCards) {
      setCurrentCard(0);
    } else if (currentCard < 0) {
      setCurrentCard(totalCards - 1);
    }
  };

  return (
    <div className="relative max-w-7xl mx-auto py-12 px-4">
      <h3 className="text-center text-gray-700 text-sm">Our Testimonial</h3>
      <h1 className="text-center text-3xl font-bold mb-8">What Our Client Say’s</h1>

      <div className="relative overflow-hidden">
        <div
          className={`flex transition-transform duration-500 ease-in-out ${isTransitioning ? '' : 'transition-none'}`}
          style={{ transform: `translateX(-${(currentCard * 100) / numVisibleCards}%)` }}
          onTransitionEnd={handleTransitionEnd}
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full sm:w-1/2 lg:w-1/3 p-4"
            >
              <div className="bg-white rounded-lg p-6 h-32 shadow-xl" style={{ boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)' }}>
                <div className="text-center text-gray-600">"{testimonial.quote}"</div>
              </div>

              <div className="mt-4 flex flex-col items-center text-center">
                <div className="mx-auto rounded-full overflow-hidden">
                  <img src={testimonial.img} alt={testimonial.name} className="object-cover" />
                </div>
                <h3 className="text-lg font-bold mt-4">{testimonial.name}</h3>
                <p className="text-gray-500">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="absolute inset-0 flex justify-between items-center px-4 opacity-0 hover:opacity-100 mt-8 transition-opacity duration-300">
          <button onClick={prevCard} className="bg-white p-2 rounded-full shadow-lg hover:bg-gray-100">
            <FaChevronLeft />
          </button>
          <button onClick={nextCard} className="bg-white p-2 rounded-full shadow-lg hover:bg-gray-100">
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
