import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ContactForm = () => {
  return (
    <div className="fixed inset-0 bg-[#F5F5F5] w-screen h-screen overflow-y-auto">
        <Navbar/>
      <div className="flex flex-col lg:flex-row justify-center items-start px-4 py-8 lg:py-20">
        <div className="w-full max-w-[550px] mt-8 h-[50vh] bg-white shadow-lg rounded-lg mb-8 lg:mb-0 lg:mr-8 flex-shrink-0">
          <form className="space-y-6 p-8">
            <div>
              <input
                type="text"
                placeholder="Name*"
                className="w-full p-3 border border-gray-300 rounded transition duration-300 text-sm font-medium flex items-center justify-center focus:outline-none focus:border-[#00BFFF]   "
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email*"
                className="w-full p-3 border border-gray-300 rounded transition duration-300 text-sm font-medium flex items-center justify-center focus:outline-none focus:border-[#00BFFF]"
              />
            </div>
            <div>
              <textarea
                placeholder="Your Comment"
                className="w-full p-3 border border-gray-300 rounded h-32 transition duration-300 text-sm font-medium flex items-center justify-center focus:outline-none focus:border-[#00BFFF]"
              ></textarea>
            </div>
            <button
              className="w-full h-[7vh] bg-[#00294B] text-white py-3 rounded hover:bg-[#00BFFF] transition duration-300"
            >
              Send Request
            </button>
          </form>
        </div>
        <div className="w-full max-w-[550px] p-4 lg:p-8 flex-shrink-0">
          <h2 className="text-xl font-bold mb-2 lg:mb-4">Our Question</h2>
          <h2 className="text-3xl lg:text-4xl font-bold mb-6 lg:mb-12">Get In Touch</h2>
          <p className="text-gray-600 mb-6 lg:mb-10">
            We are a leader in endpoint protection solutions by industry analysts, independent testing organizations and security.
          </p>
          <div className="space-y-6 lg:space-y-10">
            <div className="flex items-center">
              <div className="bg-[#00294B] p-2 rounded-full mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 lg:h-8 lg:w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
              </div>
              <span className="text-[#000] font-bold text-lg lg:text-xl">+919562966066</span>
            </div>
            <div className="flex items-center">
              <div className="bg-[#00294B] p-2 rounded-full mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 lg:h-8 lg:w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              </div>
              <span className="text-[#000] font-bold text-lg lg:text-xl">inf0@sekenz.com</span>
            </div>
            <div className="flex items-center">
              <div className="bg-[#00294B] p-2 rounded-full mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 lg:h-8 lg:w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </div>
              <span className="text-[#000] font-bold text-lg lg:text-xl">  Sekenz,E-Square,Edapally junction,Edapally,
              Ernamkulam-682024</span>
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-8  ">
        <div className="w-full h-[450px]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16884.429421095363!2d76.3000182801826!3d10.0304480154583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080da53444d5e9%3A0xb46c57c6b1bc9aff!2sEdappally%2C%20Kochi%2C%20Kerala!5e1!3m2!1sen!2sin!4v1728470759558!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default ContactForm;
