import React from 'react'
import HeroSection from '../components/HeroSection'
import FeaturesGrid from '../components/FeaturesGrid'
import ExploreComponent from '../components/ExploreComponent'
import CardComponent from '../components/CardComponent'
import ImageGallery from '../components/ImageGallery'
import TeamSection from '../components/TeamSection'
import TestimonialSlider from '../components/TestimonialSlider'
import VideoPlayer from '../components/VideoPlayer'
import FAQSection from '../components/FAQSection'
import LogoSlider from '../components/LogoSlider'
import NewsArticles from '../components/NewsArticles'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


const Home = () => {
  return (
    <main>

       <Navbar/>
        <HeroSection/>
        <FeaturesGrid/>
        <ExploreComponent/>
        <CardComponent/>
        <ImageGallery/>
        <TeamSection/>
        <TestimonialSlider/>
        <VideoPlayer/>
        <FAQSection/>
        <LogoSlider/>
        <NewsArticles/>
        <Footer/>
        

    </main>
  )
}

export default Home
