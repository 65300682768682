import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import lgImg from "../assets/images/lgimg.jpg";
import smImg1 from "../assets/images/smimg1.jpg";
import smImg2 from "../assets/images/smimg2.jpg";
import TwoHandSvg from "../assets/svg/TwoHandSvg";
import HandDollorsvg from "../assets/svg/HandDollorsvg";
import TickSymSvg from "../assets/svg/TickSymSvg";

const ExploreComponent = () => {
  const countUpRef = useRef(null);
  const [startCountUp, setStartCountUp] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000 });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartCountUp(true);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (countUpRef.current) {
      observer.observe(countUpRef.current);
    }

    return () => {
      if (countUpRef.current) {
        observer.unobserve(countUpRef.current);
      }
    };
  }, []);

  return (
    <div className="flex flex-col sm:flex-row bg-gray-100 py-10 px-4 lg:px-56 h-full font-poppins">
      {/* Images Section */}
      <div className="w-full sm:w-1/2 grid grid-cols-2 gap-4 h-full mb-8 sm:mb-0">
        <div className="col-span-1 row-span-2 h-full">
          <img
            src={lgImg}
            alt="Large Image"
            className="w-full h-full rounded-lg object-cover"
          />
        </div>

        <div className="col-span-1 grid grid-rows-2 gap-4 h-full">
          <div className="row-span-1 h-full">
            <img
              src={smImg1}
              alt="Small Image 1"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          <div className="row-span-1 h-full">
            <img
              src={smImg2}
              alt="Small Image 2"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="w-full sm:w-1/2 sm:pl-8 flex flex-col justify-start h-full">
        <p className="text-[#00294B] uppercase tracking-widest font-semibold text-sm">
          Explore Features
        </p>
        <h1 className="text-3xl text-[#1B1F2E] sm:text-4xl font-bold mt-2 leading-tight">
          Join The New Innovation With Technical Workforce
        </h1>
        <p className="mt-4 text-gray-700 leading-relaxed">
          Sekenz Group is a leading web and app development company specializing
          in custom solutions for businesses worldwide. We deliver innovative,
          user-friendly, and scalable digital products, ensuring exceptional
          quality and client satisfaction.
        </p>

        <div className="mt-8 grid lg:grid-cols-2 gap-10" ref={countUpRef}>
          {/* Facility Section */}
          <div>
            <h2 className="text-2xl font-semibold text-[#1B1F2E] mb-4">Our Facility</h2>
            <p className="text-gray-600 leading-relaxed">
              Sekenz Group offers custom web and app development, delivering scalable, user-friendly solutions with top quality and client satisfaction.
            </p>

            <ul className="mt-4 text-gray-600 list-none space-y-4">
              <li className="flex items-center">
                <TickSymSvg className="mr-3 flex-shrink-0" />
                <span>Scalable Software Solutions</span>
              </li>
              <li className="flex items-center">
                <TickSymSvg className="mr-3 flex-shrink-0" />
                <span>User-Centric Development Focus</span>
              </li>
              <li className="flex items-center">
                <TickSymSvg className="mr-3 flex-shrink-0" />
                <span>E-commerce Quality Assurance</span>
              </li>
            </ul>
          </div>

          {/* Stats Section */}
          <div className="flex flex-col space-y-8">
            <div className="flex items-center space-x-4">
              <div className="text-4xl text-[#1B1F2E]">
                <TwoHandSvg />
              </div>
              <div>
                <div className="text-4xl font-bold text-[#1B1F2E] leading-none">
                  {startCountUp ? (
                    <CountUp start={0} end={86} duration={3} suffix="%" />
                  ) : (
                    "0%"
                  )}
                </div>
                <p className="mt-2 text-xl font-bold text-gray-600">
                  Coal Production
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="text-4xl text-[#1B1F2E]">
                <HandDollorsvg />
              </div>
              <div>
                <div className="text-4xl font-bold text-[#1B1F2E] leading-none">
                  {startCountUp ? (
                    <CountUp start={0} end={866} duration={3} suffix="+" />
                  ) : (
                    "0+"
                  )}
                </div>
                <p className="mt-2 text-xl font-bold text-gray-600">
                  Expert Developers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreComponent;