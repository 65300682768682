import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaPhone, FaMapMarkerAlt, FaHeart } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { HiMenu, HiX } from 'react-icons/hi';
import logo from '../assets/logo.png';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    const handleConsultingClick = () => {
        window.location.href = 'tel:+919562966066';
    };

    return (
        <div className="bg-[#00274d]">
            {/* Top bar */}
            <div className="text-white py-2 px-4 md:px-8 flex flex-wrap justify-between items-center text-xs md:text-sm">
                {/* Left Section */}
                <div className="flex flex-col md:flex-row items-center md:space-x-8 w-full md:w-auto space-y-2 md:space-y-0">
                    <div className="flex flex-wrap items-center space-x-4">
                        <div className="flex items-center">
                            <FaMapMarkerAlt className="mr-2" />
                            <span>Sekenz E-Square</span>
                        </div>
                        <div className="flex items-center">
                            <FaPhone className="mr-2" />
                            <span>+919562966066</span>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <FaHeart className="mr-2" />
                        <span>Welcome to our site</span>
                    </div>
                </div>
                {/* Right Section */}
                <div className="flex justify-center md:justify-start space-x-4 md:space-x-8 mt-4 md:mt-0 w-full md:w-auto">
                    <FaFacebook className="cursor-pointer" />
                    <FaTwitter className="cursor-pointer" />
                    <FaInstagram className="cursor-pointer" />
                </div>
            </div>
      
            {/* Main Navbar */}
            <div className="bg-white py-6 px-8 flex justify-between items-center">
                {/* Logo */}
                <div>
                    <img src={logo} alt="Logo" className="h-16" />
                </div>

                {/* Mobile menu toggle button */}
                <button onClick={toggleMobileMenu} className="text-3xl md:hidden">
                    {isMobileMenuOpen ? <HiX /> : <HiMenu />}
                </button>

                {/* Desktop Menu */}
                <div className="hidden md:flex items-center space-x-12 text-sm font-semibold text-[#00274d]">
                    <a href="/" className="hover:text-blue-500 transition-colors duration-300">Home</a>
                    <a href="/about" className="hover:text-blue-500 transition-colors duration-300">About</a>
                    <a href="/services" className="hover:text-blue-500 transition-colors duration-300">Services</a>
                    <a href="/Careers" className="hover:text-blue-500 transition-colors duration-300">Career</a>
                    <a href="/contact" className="hover:text-blue-500 transition-colors duration-300">Contact</a>
                </div>

                {/* Search and CTA Button */}
                <div className="hidden md:flex items-center space-x-6">
                    <button 
                        onClick={handleConsultingClick}
                        className="bg-[#00274d] text-white py-3 px-6 rounded-md text-sm hover:bg-blue-500 transition duration-300 flex items-center"
                    >
                        <FaPhone className="mr-2" />
                        Consulting
                    </button>
                </div>
            </div>

            {/* Mobile menu (visible on smaller screens) */}
            {isMobileMenuOpen && (
                <div className="bg-white text-black md:hidden">
                    <div className="py-4 px-4 space-y-2 text-sm">
                        <a href="/" className="block py-2 hover:text-blue-500">Home</a>
                        <a href="/about" className="block py-2 hover:text-blue-500">About</a>
                        <a href="/services" className="block py-2 hover:text-blue-500">Services</a>
                        <a href="/Careers" className="block py-2 hover:text-blue-500">Career</a>
                        <a href="/contact" className="block py-2 hover:text-blue-500">Contact</a>
                    </div>
                    <div className="px-4 py-2">
                        <button 
                            onClick={handleConsultingClick}
                            className="bg-[#00274d] text-white py-2 px-4 rounded-md text-sm w-full hover:bg-blue-500 transition duration-300 flex items-center justify-center"
                        >
                            <FaPhone className="mr-2" />
                            Consulting
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;