import React, { useEffect, useState } from "react";

import ImgB1 from "../assets/images/b1.png";
import ImgB2 from "../assets/images/b2.png";
import ImgB3 from "../assets/images/b3.png";
import ImgB4 from "../assets/images/b4.png";
import ImgB5 from "../assets/images/b5.png";

const logos = [ImgB1, ImgB2, ImgB3, ImgB4, ImgB5];

const LogoSlider = () => {
  const [visibleLogos, setVisibleLogos] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const totalLogos = logos.length;

  useEffect(() => {
    const updateVisibleLogos = () => {
      const windowWidth = window.innerWidth;
      let numberOfVisibleLogos;

      if (windowWidth < 640) {
        numberOfVisibleLogos = 2;
      } else if (windowWidth < 1024) {
        numberOfVisibleLogos = 4;
      } else {
        numberOfVisibleLogos = totalLogos;
      }

      const nextIndex = (slideIndex + 1) % totalLogos;
      const newLogos = logos.slice(nextIndex, nextIndex + numberOfVisibleLogos);

      const logosToDisplay =
        newLogos.length < numberOfVisibleLogos
          ? [
              ...newLogos,
              ...logos.slice(0, numberOfVisibleLogos - newLogos.length),
            ]
          : newLogos;

      setVisibleLogos(logosToDisplay);
    };

    updateVisibleLogos();

    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % totalLogos);
    }, 5000);

    window.addEventListener("resize", updateVisibleLogos);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", updateVisibleLogos);
    };
  }, [slideIndex, totalLogos]);

  return (
    <div className="bg-[#0787d9] h-72 flex items-center justify-center relative overflow-hidden">
      <div className="flex space-x-28 md:space-x-44  transition-transform duration-500">
        {visibleLogos.map((logo, index) => (
          <div key={index} className="flex-shrink-0">
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-20 object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoSlider;
