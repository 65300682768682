import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


// Import your page components here
import Home from './pages/Home';
import About from './pages/About';
import ContactForm from './pages/Contact';
import Services from './pages/Services';
import CareersPage from './components/CareersPage';



const App = () => {
  return (
    <Router>
      
      <div className="App">
        {/* Your header component would go here */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/services" element={<Services />} />
            <Route path="/Careers" element={<CareersPage />} />
                     
          </Routes>
        </main>
        
      </div>
      
    </Router>
  );
};

export default App;