import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ImageGallery from '../components/ImageGallery';

const ServiceCard = ({ icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.03 }}
    className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center h-full"
  >
    <span className="text-5xl mb-4">{icon}</span>
    <h3 className="text-xl font-semibold text-gray-800 mb-3 font-poppins">{title}</h3>
    <p className="text-gray-600 leading-relaxed font-poppins text-sm">{description}</p>
  </motion.div>
);

const Services = () => {
  const navigate = useNavigate();

  const services = [
    { icon: '🌐', title: "Web Development", description: "Custom websites tailored to your industry, designed for performance, scalability, and a seamless user experience." },
    { icon: '📱', title: "App Development", description: "Innovative mobile applications promoting engagement and growth. Our apps are designed to be user-friendly and feature-rich." },
    { icon: '💻', title: "Software Development", description: "Comprehensive software solutions for your business needs. We specialize in developing robust, scalable, and efficient software." },
    { icon: '📢', title: "Digital Marketing", description: "Data-driven strategies to enhance your online presence. We use SEO, PPC, social media, and content marketing." },
    { icon: '🎨', title: "UI/UX Designing", description: "Stunning, intuitive interfaces for seamless user experiences. Our design process focuses on creating visually appealing designs." },
    { icon: '🛒', title: "E-Commerce Development", description: "Robust online stores to boost your digital sales. We develop secure, scalable, and user-friendly e-commerce platforms." },
    { icon: '🎓', title: "Study Abroad", description: "Comprehensive guidance for international education experiences. From choosing institutions to visa applications." },
    { icon: '👔', title: "Abroad Recruitment", description: "Connecting global talent with international opportunities. We assist candidates in finding the right job overseas." }
  ];

  return (
    
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 font-poppins">
      <Navbar/>
      {/* Hero Section */}
      <section className="relative py-24 px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-blue-600 to-indigo-700">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="relative max-w-6xl mx-auto text-center"
        >
          <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6 font-poppins leading-tight">
            Our Comprehensive IT and 
            <br />
            Digital Marketing Services
          </h1>
          <p className="text-xl text-gray-200 max-w-3xl mx-auto font-poppins">
            We create digital products and experiences that exceed expectations 
            <br className="hidden sm:block" />
            and drive business growth.
          </p>
        </motion.div>
      </section>

      {/* Services Grid */}
      <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-white py-20">
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto text-center px-4 sm:px-6 lg:px-8"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4 font-poppins">
            Ready to Transform Your Business?
          </h2>
          <p className="text-xl text-gray-600 mb-8 font-poppins">
            Let's collaborate to bring your digital vision to life.
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-4 px-8 rounded-lg shadow-lg transition-colors duration-300 font-poppins"
            onClick={() => navigate('/contact')}
          >
            Get in Touch
          </motion.button>
        </motion.div>
      </section>
      <ImageGallery/>
      <Footer/>
    </div>
  );
};

export default Services;