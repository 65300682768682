import React from 'react';

const RotatingDots = () => {
  // Configure circles with their sizes and number of dots
  const circles = [
    { size: 96, dots: 12, duration: 30 }, // Outermost
    { size: 80, dots: 10, duration: 25 },
    { size: 64, dots: 8, duration: 20 },
    { size: 48, dots: 6, duration: 15 },
    { size: 32, dots: 4, duration: 10 }, // Innermost
  ];

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gray-900">
      <div className="relative">
        {circles.map((circle, circleIndex) => {
          const dots = Array(circle.dots).fill(null);
          const isEven = circleIndex % 2 === 0;
          
          return (
            <div
              key={`circle-${circleIndex}`}
              className="absolute rounded-full border border-white/5"
              style={{
                width: `${circle.size * 4}px`,
                height: `${circle.size * 4}px`,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                animation: `rotate${isEven ? '' : 'Reverse'} ${circle.duration}s linear infinite`,
              }}
            >
              {dots.map((_, dotIndex) => {
                const angle = (360 / dots.length) * dotIndex;
                // Calculate opacity based on circle index with lower base opacity
                const opacity = 0.4 - (circleIndex * 0.07);
                
                return (
                  <div
                    key={`dot-${circleIndex}-${dotIndex}`}
                    className="absolute w-2 h-2 rounded-full"
                    style={{
                      top: '50%',
                      left: '50%',
                      transform: `rotate(${angle}deg) translate(${circle.size * 2}px, -50%)`,
                      backgroundColor: `rgba(255, 255, 255, ${opacity})`,
                      boxShadow: `0 0 10px rgba(255, 255, 255, ${opacity * 0.3}), 0 0 20px rgba(255, 255, 255, ${opacity * 0.2})`
                    }}
                  />
                );
              })}
            </div>
          );
        })}

        <style>
          {`
            @keyframes rotate {
              from {
                transform: translate(-50%, -50%) rotate(0deg);
              }
              to {
                transform: translate(-50%, -50%) rotate(360deg);
              }
            }

            @keyframes rotateReverse {
              from {
                transform: translate(-50%, -50%) rotate(360deg);
              }
              to {
                transform: translate(-50%, -50%) rotate(0deg);
              }
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default RotatingDots;