import React, { useState } from 'react';
import Modal from 'react-modal';
import img1 from "../assets/images/fordesign.jpg"

Modal.setAppElement('#root'); 

const VideoPlayer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div
      className="h-[50vh] bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: `url(${img1})` }}
    >
      <button
        onClick={openModal}
        className="bg-blue-500 rounded-full w-16 h-16 flex items-center justify-center text-white text-2xl"
      >
        ▶
      </button>

      <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  className="fixed inset-0 flex items-center justify-center"
  overlayClassName="fixed inset-0 bg-black bg-opacity-80"
>
  <div className="relative w-11/12 md:w-2/3 lg:w-1/2 h-3/4 bg-white rounded-lg shadow-lg">
    <iframe
      className="w-full h-full"
      src="https://www.youtube.com/embed/your-video-id" // Replace with your YouTube video ID
      title="YouTube Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
  <button
    onClick={closeModal}
    className="absolute top-5 right-5 text-black text-3xl z-10"
  >
    ✖
  </button>
</Modal>

    </div>
  );
};

export default VideoPlayer;
