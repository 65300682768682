import React from "react";
import { FaCar, FaBicycle, FaBus, FaTruck, FaMotorcycle, FaPlane, FaShip, FaRocket } from "react-icons/fa"; // Import different icons

const cardData = [
    {
        icon: <FaCar />,
        heading: "Web development",
        description: "Custom websites tailored to your industry, designed for performance, scalability, and a seamless user experience. From simple landing pages to complex web applications, we have you covered.",
    },
    {
        icon: <FaBicycle />,
        heading: "App development",
        description: "Innovative mobile applications promoting engagement and growth. Our apps are designed to be user-friendly, feature-rich, and compatible with both iOS and Android platforms.",
    },
    {
        icon: <FaBus />,
        heading: "Software development",
        description: "Comprehensive software solutions for your business needs. We specialize in developing robust, scalable, and efficient software that drives business success and enhances operational efficiency.",
    },
    {
        icon: <FaTruck />,
        heading: "Digital Marketing",
        description: "Data-driven strategies to enhance your online presence. We use SEO, PPC, social media, and content marketing to reach your target audience and achieve your marketing goals.",
    },
    {
        icon: <FaMotorcycle />,
        heading: "UI/UX Designing",
        description: "Stunning, intuitive interfaces for seamless user experiences. Our design process focuses on creating visually appealing and user-centric designs that improve user satisfaction and engagement.",
    },
    {
        icon: <FaPlane />,
        heading: "E-Commerce Development",
        description: "Robust online stores to boost your digital sales. We develop secure, scalable, and user-friendly e-commerce platforms that offer a smooth shopping experience and drive conversions.",
    },
    {
        icon: <FaShip />,
        heading: "Study Abroad",
        description: "Comprehensive guidance for international education experiences. From choosing the right institution to visa applications, we provide end-to-end support to help you achieve your academic goals abroad.",
    },
    {
        icon: <FaRocket />,
        heading: "Abroad Recruitment",
        description: "Connecting global talent with international opportunities. We assist candidates in finding the right job overseas and help companies in recruiting the best talent from around the world.",
    },
];

const CardComponent = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4 pt-20 bg-gray-300">
          
            <h3 className="text-lg font-bold text-gray-600">Explor Services</h3>

           
            <h1 className="text-3xl font-bold text-gray-800 mb-10">We Offering Best Service in</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-screen-xl">
                {cardData.map((card, i) => (
                    <div 
                        key={i}
                        className="relative group border-l-2 border-[#00294B]  bg-white p-6 overflow-hidden flex flex-col"
                    >
                        <div className="absolute inset-0 bg-[#00294B]  -translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out"></div>
                        <div className="relative z-10 flex-grow">
                            <div className="relative z-10 mb-4  group-hover:text-white">
                                <div className="w-12 h-12 flex group-hover:bg-white items-center group-hover:text-[#00294B] justify-center rounded-full bg-[#00294B] text-white">
                                    {card.icon}
                                </div>
                            </div>

                            <h2 className="text-xl font-semibold mb-2 relative z-10 group-hover:text-white">
                                {card.heading}
                            </h2>

                            <p className="text-gray-700 relative z-10 group-hover:text-white">
                                {card.description}
                            </p>
                        </div>

                        <div className="mt-auto">
                            <button className="justify-center mt-4 px-4 py-2 relative text-[#00294B] border rounded bg-white group-hover:bg-white">
                                Read More
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CardComponent;
