import React from "react";
import AndroidSvg from "../assets/svg/AndroidSvg";
import SecuritySvg from "../assets/svg/SecuritySvg";
import ItSvg from "../assets/svg/ItSvg";
import MainteImg from "../assets/images/maintenace.jpg";

const FeaturesGrid = () => {
  return (
    <div className="bg-gray-100 py-16">
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Maintenance Service */}
          <div className="relative group bg-white p-6 md:p-8 rounded-lg shadow-md overflow-hidden">
            <div className="group-hover:-translate-y-[150%] group-hover:scale-0 group-hover:opacity-0 transform transition duration-500">
              <div className="flex justify-center items-center mb-4 text-blue-500 text-4xl">
                <SecuritySvg />
              </div>
            </div>

            <div className="group-hover:-translate-y-20 transform transition duration-500 space-y-4">
              <h3 className="text-xl font-bold">
                Comprehensive Maintenance Service
              </h3>
              <p className="text-gray-600">
                We ensure your IT systems are always up and running with minimal downtime. From hardware repairs to software updates, we've got you covered.
              </p>
            </div>

            <div className="absolute inset-0 flex justify-center items-center">
              <img
                src={MainteImg}
                alt="Maintenance Service"
                className="w-full h-0 group-hover:h-[30%] group-hover:bottom-0 group-hover:opacity-100 opacity-0 transform translate-y-12 group-hover:translate-y-0 transition duration-500 absolute bottom-0"
              />
            </div>
          </div>

          {/* IT Security */}
          <div className="relative group bg-white p-6 md:p-8 rounded-lg shadow-md overflow-hidden">
            <div className="group-hover:-translate-y-[150%] group-hover:scale-0 group-hover:opacity-0 transform transition duration-500">
              <div className="flex justify-center items-center mb-4 text-green-500 text-4xl">
                <ItSvg />
              </div>
            </div>

            <div className="group-hover:-translate-y-20 transform transition duration-500 space-y-4">
              <h3 className="text-xl font-bold">
                Advanced IT Security Solutions
              </h3>
              <p className="text-gray-600">
                Protect your business with state-of-the-art cybersecurity services, including firewalls, encryption, and real-time monitoring to ensure your data is safe.
              </p>
            </div>

            <div className="absolute inset-0 flex justify-center items-center">
              <img
                src={MainteImg}
                alt="IT Security"
                className="w-full h-0 group-hover:h-[30%] group-hover:bottom-0 group-hover:opacity-100 opacity-0 transform translate-y-12 group-hover:translate-y-0 transition duration-500 absolute bottom-0"
              />
            </div>
          </div>

          {/* IT Services */}
          <div className="relative group bg-white p-6 md:p-8 rounded-lg shadow-md overflow-hidden">
            <div className="group-hover:-translate-y-[150%] group-hover:scale-0 group-hover:opacity-0 transform transition duration-500">
              <div className="flex justify-center items-center mb-4 text-red-500 text-4xl">
                <AndroidSvg />
              </div>
            </div>

            <div className="group-hover:-translate-y-20 transform transition duration-500 space-y-4">
              <h3 className="text-xl font-bold">
                Full Spectrum IT Services
              </h3>
              <p className="text-gray-600">
                From cloud computing and networking to custom software development, we provide end-to-end IT solutions tailored to your business needs.
              </p>
            </div>

            <div className="absolute inset-0 flex justify-center items-center">
              <img
                src={MainteImg}
                alt="IT Services"
                className="w-full h-0 group-hover:h-[30%] group-hover:bottom-0 group-hover:opacity-100 opacity-0 transform translate-y-12 group-hover:translate-y-0 transition duration-500 absolute bottom-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesGrid;