import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import image1 from '../assets/ft1.jpg';
import image2 from '../assets/ft2.jpg';
import image3 from '../assets/ft3.jpg';
import image4 from '../assets/ft4.jpg';
import image5 from '../assets/ft5.jpg';
import image6 from '../assets/ft6.jpg';

const Footer = () => {
    return (
        <footer className="bg-[#00294B] text-white py-12 px-4">
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
                
                {/* Our Contact Section */}
                <div>
                    <h3 className="text-2xl font-semibold mb-4">Our Contact</h3>
                    <p className="text-sm mb-4">We help businesses maximize their online presence There.</p>
                    <p className="flex items-center mb-2 text-sm">
                        <FaMapMarkerAlt className="mr-2" />
                        Sekenz,E-Square,Edapally junction,Edapally,
                        Ernamkulam-682024

                    </p>
                    <p className="flex items-center mb-2 text-sm">
                        <FaPhone className="mr-2" />
                        Telephone: (91)9562966066
                    </p>
                    <p className="flex items-center text-sm">
                        <FaEnvelope className="mr-2" />
                        Email: sekenz.com
                    </p>
                </div>

                {/* Basic Sites Section */}
                <div>
                    <h3 className="text-2xl font-semibold mb-4">Basic Sites</h3>
                    <ul className="text-sm">
                        <li className="mb-2">web desingning</li>
                        <li className="mb-2">Contact</li>
                        <li className="mb-2">Testimonials</li>
                        <li className="mb-2">faq questions</li>
                        <li>Maps</li>
                    </ul>
                </div>

                {/* Our Service Section */}
                <div>
                    <h3 className="text-2xl font-semibold mb-4">Our Service</h3>
                    <ul className="text-sm">
                        <li className="mb-2">Mobile Layout Included</li>
                        <li className="mb-2">Multipurpose</li>
                        <li className="mb-2">Language Support</li>
                        <li className="mb-2">Testimonials Easy</li>
                        <li>Installation</li>
                    </ul>
                </div>

                {/* Gallery Section */}
                <div>
                    <h3 className="text-2xl font-semibold mb-4">Gallery</h3>
                    <div className="grid grid-cols-3 gap-2">
                        <img src={image1} alt="Gallery 1" className="w-full h-20 object-cover" />
                        <img src={image2} alt="Gallery 2" className="w-full h-20 object-cover" />
                        <img src={image3} alt="Gallery 3" className="w-full h-20 object-cover" />
                        <img src={image4} alt="Gallery 4" className="w-full h-20 object-cover" />
                        <img src={image5} alt="Gallery 5" className="w-full h-20 object-cover" />
                        <img src={image6} alt="Gallery 6" className="w-full h-20 object-cover" />
                    </div>
                </div>
            </div>

            {/* Footer Bottom Section */}
            <div className="border-t border-gray-600 pt-4 flex flex-col sm:flex-row justify-between items-center text-sm">
                <p>Copyright © consalt all rights reserved.</p>
                <div className="flex space-x-6 mt-2 sm:mt-0">
                    <a href="#" className="hover:text-blue-300">About</a>
                    <a href="#" className="hover:text-blue-300">Pricing</a>
                    <a href="#" className="hover:text-blue-300">Contact</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;