import React, { useState } from 'react';
import FaqImg from '../assets/images/faqimg.png';

const FAQSection = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const faqs = [
    {
      question: '1. Do You Need A Business Service?',
      answer: 'If you are looking to streamline operations, improve efficiency, or enhance customer experiences, then a business service can provide the necessary expertise and tools to help your business thrive.'
    },
    {
      question: '2. Is Business Service A Scam?',
      answer: 'No, reputable business services are designed to support your organization with professional expertise. It’s important to research and choose a credible provider with proven results to avoid scams.'
    },
    {
      question: '3. How To Get The Most Out Of Your Business Service?',
      answer: 'To maximize the benefits, clearly define your goals, communicate effectively with your service provider, and regularly assess the outcomes to ensure alignment with your business objectives.'
    },
    {
      question: '4. What Are The Benefits Of Business Service?',
      answer: 'Business services can lead to increased efficiency, cost savings, access to specialized expertise, and improved customer satisfaction, all of which contribute to the overall growth and success of your company.'
    },
  ];
  

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <div className="flex flex-col md:flex-row items-start py-20 space-x-2 px-6 xl:px-52">
    
      <img
        src={FaqImg}
        alt="FAQ Image"
        className="w-full md:w-1/2 rounded-xl mb-8 xl:mb-0 xl:mr-8"
      />
    
      <div className="w-full xl:w-1/2">
        <h2 className="text-left text-lg font-semibold mb-2">OUR FAQ</h2>
        <h1 className="text-4xl font-bold mb-14">Frequently Asked Questions?</h1>
        <div>
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`rounded-lg shadow-lg mb-4 transition-all duration-300 ${
                activeQuestion === index
                  ? 'bg-blue-900 text-white'
                  : 'bg-white hover:bg-blue-900 hover:text-white'
              }`}
            >
              <div
                className="flex justify-between items-center p-4 cursor-pointer"
                onClick={() => toggleQuestion(index)}
              >
                <span className="text-lg font-medium">{faq.question}</span>
                <span className="text-xl">
                  {activeQuestion === index ? '▲' : '▼'}
                </span>
              </div>
              {activeQuestion === index && (
                <div className="p-4 bg-white text-black rounded-b-lg">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
