import React, { useState, useEffect } from "react";
import { FaShareAlt } from "react-icons/fa";
import TeamImg1 from "../assets/images/team1.png";
import joyal from "../assets/joyal.jpg";
import keerthi from "../assets/Keerthi.jpg";
import nizam from "../assets/Nizam .jpg";
import anjali from "../assets/ayyapan.jpg";
import anusree from "../assets/anusree.jpg";

const ProfileCard = ({ image, name, role }) => {
  return (
    <div className="rounded-lg shadow-lg overflow-hidden transform transition-all duration-500 ease-in-out h-full bg-white">
      <div className="relative group">
        <img
          src={image}
          alt={name}
          className="w-full h-40 sm:h-48 md:h-52 lg:h-60 object-cover group-hover:opacity-50 transition duration-300"
        />
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition duration-300" />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300">
          <div className="bg-gray-800 p-2 sm:p-3 rounded-full flex items-center justify-center transform hover:scale-110 transition duration-300">
            <FaShareAlt className="text-white text-xl sm:text-2xl lg:text-3xl" />
          </div>
        </div>
      </div>

      <div className="p-2 sm:p-4 text-center">
        <h3 className="font-bold text-base sm:text-lg mb-1">{name}</h3>
        <p className="text-gray-500 text-sm sm:text-base">{role}</p>
      </div>
    </div>
  );
};

const TeamSection = () => {
  const profiles = [
    { image: TeamImg1, name: "Zames Sienna", role: "Director & CEO" },
    { image: joyal, name: "Joyal A.j", role: "Team-Lead" },
    { image: nizam, name: "Mohammed Nizam", role: "Backend-Developer" },
    { image: keerthi, name: "Keerthi.K", role: "Frontend developer" },
    { image: anjali, name: "Anjali Ayyappan", role: "Frontend developer" },
    { image: anusree, name: "Anusree.s", role: "Frontend developer" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [visibleCount, setVisibleCount] = useState(4);

  // Handle responsive visible cards
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {  // Changed from 640 to 768
        setVisibleCount(2);  // Changed from 1 to 2
      } else if (window.innerWidth < 1024) {
        setVisibleCount(3);
      } else {
        setVisibleCount(4);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle carousel rotation
  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % profiles.length);
        setIsTransitioning(false);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [profiles.length]);

  const getVisibleProfiles = () => {
    const visibleProfiles = [];
    for (let i = 0; i < visibleCount; i++) {
      const index = (currentIndex + i) % profiles.length;
      visibleProfiles.push(profiles[index]);
    }
    return visibleProfiles;
  };

  return (
    <section className="min-h-[60vh] sm:min-h-[70vh] lg:min-h-[80vh] flex flex-col justify-center items-center bg-gray-100 px-4 py-8">
      <style>
        {`
          .carousel-container {
            overflow: hidden;
            position: relative;
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
          }

          .carousel-grid {
            transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
          }

          .profile-card {
            opacity: 0;
            transform: translateX(20px);
            animation: slideIn 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
            height: 100%;
          }

          @keyframes slideIn {
            0% {
              opacity: 0;
              transform: translateX(20px);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .transitioning .profile-card {
            animation: slideOut 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
          }

          @keyframes slideOut {
            0% {
              opacity: 1;
              transform: translateX(0);
            }
            100% {
              opacity: 0;
              transform: translateX(-20px);
            }
          }

          @media (max-width: 767px) {
            .profile-card {
              max-width: none;
              margin: 0;
            }
          }
        `}
      </style>
      
      <div className="text-center mb-8 px-4">
        <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-gray-800">
          OUR MEMBERS
        </h2>
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-900">
          The Company Leaders
        </h1>
      </div>

      <div className="carousel-container">
        <div 
          className={`
            grid 
            grid-cols-2 
            sm:grid-cols-2 
            md:grid-cols-3 
            lg:grid-cols-4 
            gap-4 
            sm:gap-6 
            lg:gap-8 
            carousel-grid
            ${isTransitioning ? 'transitioning' : ''}
          `}
        >
          {getVisibleProfiles().map((profile, index) => (
            <div
              key={`${currentIndex}-${index}`}
              className="profile-card"
              style={{
                animationDelay: `${index * 100}ms`
              }}
            >
              <ProfileCard
                image={profile.image}
                name={profile.name}
                role={profile.role}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;